import React from "react";
import styled from "styled-components";

const Phrase = styled.div`
  font-weight: 600;
  font-size: 2.5rem;
  color: #000;
  color: #333;
  margin: 0 auto;
  line-height: 1.5;
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin: 0 auto;
  line-height: 1.3;
`;

const Container = styled.div`
  padding: 3%;
  margin: auto a {
    color: #2f80ed;
    border-bottom: 1px solid #d3d3d3;
    transition: all 300ms ease;
  }
  .phrase a:hover {
    opacity: 0.5;
  }
`;

const Landing = () => (
  <div>
    <Container className="max-w-screen-xl mx-auto p-5">
      <Phrase className="phrase">
        <span className="title">Privacy Policy</span>
      </Phrase>{" "}
      <br />
      <Text>
        <li>
          Enlight collects a small amount of information, such as your account
          profile, and uses cookies to serve content related to your account.{" "}
        </li>
        <li>
          Google Analytics is used to help determine how to best build this
          website to serve users.
        </li>
        <li>
          Google and Github Login is used to facilitate the login process if you
          choose to use it.
        </li>
        <li>
          You can request to have all of your data deleted from the system at
          any time by emailing team@enlight.nyc
        </li>
        <li>
          {" "}
          The data Enlight collects includes, but is not limited to: a user's
          username, name, email address, profile picture, and their
          user-generated content on the platform.
        </li>
        <li>
          All data is stored securely on the Firebase Cloud hosting platform.
          Your data is secure and is not shared with any third party. We take
          precautions to protect your information. When you submit sensitive
          information via our website, your information is protected.{" "}
        </li>

        <br />
      </Text>
    </Container>
  </div>
);

export default Landing;
